<template>
	<div class="input-wrapper">
		<label :for="name" class="label__input" :class="{'required': required}">{{ label }}</label>
		<select
			:value="value"
			:name="name"
			ref="rf_sub"
			:id="name"
			:required="required"
			:disabled="disabled"
			class="select input"
			:class="{'disabled': disabled}"
			autocomplete="true"
			@change="$emit('change', $event.target.value)"
		>
			<option v-if="firstOption" :value="null" selected disabled>{{ firstOption || options[0] }}</option>
			<option v-for="option, key in options" :key="key">{{ option }}</option>
		</select>
		<span class="expand-icon"></span>
		<span class="validity-icon"></span>
	</div>
</template>

<script>
export default {
	name: "SelectInput",
	model: {
		prop: 'value',
		event: 'change',
	},
	props: {
		value: {
			type: [String, Number],
			required: false,
		},
		options: {
			type: Array,
			required: false,
		},
		firstOption: {
			type: String,
			required: false,
		},
		name: {
			type: String,
			required: true,
		},
		required: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: 'Лейбл инпута'
		},
		type: {
			type: String,
			default: 'text',
			validate: (v) => ['text', 'number', 'textarea'].includes(v)
		},
		size: {
			type: Array,
			default: () => ['80', '10'],
			validate: (v) => v.length === 2
		}
	}
}
</script>

<style>

</style>
