<template>
	<div class="input-wrapper">
		<label :for="name" class="label__input" :class="{'required': required}">{{ label }}</label>
		<input
			:type="type"
			:value="value"
			:name="name"
			:id="name"
			:required="required"
			:disabled="disabled"
			:class="['input', {'textarea': type==='textarea', 'disabled': disabled}]"
			:rows="size[0]"
			:cols="size[1]"
			:placeholder="placeholder"
			:pattern="pattern"
			@input="$emit('input', $event.target.value)"
		>
		<span class="validity-icon"></span>
	</div>
</template>

<script>
export default {
	name: "BasicInput",
	props: {
		value: {
			type: [String, Number],
			required: false,
		},
		name: {
			type: String,
			required: true,
		},
		required: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: 'Лейбл инпута'
		},
		type: {
			type: String,
			default: 'text',
			validate: (v) => ['text', 'number', 'textarea', 'date', 'tel', 'email'].includes(v)
		},
		placeholder: {
			type: String,
			default: '',
		},
		pattern: {
			type: String,
			default: '.*',
		},
		size: {
			type: Array,
			default: () => ['80', '10'],
			validate: (v) => v.length === 2
		}
	}
}
</script>

<style>

</style>
