<template>
	<label class="label__checkbox">
		<input
			:id="id"
			:name="name"
			type="checkbox"
			:value="value"
			@change="$emit('change', $event.target.checked)"
		>
			{{ label }}
	</label>
</template>

<script>
export default {
	name: "InputCheckbox",
	model: {
		prop: 'value',
		event: 'change',
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		value: {
			type: Boolean,
			default: true,
		},
	}
}
</script>
