<template>
	<section v-if="!getStatus">
		<form @submit.prevent="submit" v-if="selectOptions.subjects !== undefined">
			<fieldset class="form__ask-expert--wrapper">
				<fieldset class="form-row form-row__one-column form-row__heading">
					<h2 class="subheading">Запись реестра</h2>
				</fieldset>
				<fieldset class="form-row form-row__two-columns">
					<basic-input
						type="number"
						name="registry_item_number"
						v-model="filledData.registry_item_number"
						:disabled="disabled"
						label="Номер реестровой записи предприятия"
						required
					/>
					<basic-input
						type="date"
						name="date_created"
						v-model="filledData.date_created"
						:disabled="disabled"
						label="Дата внесения реестровой записи предприятия"
						required
					/>
				</fieldset>
				<fieldset class="form-row form-row__one-column">
					<basic-input
						name="education_organization"
						v-model="filledData.education_organization"
						:disabled="disabled"
						label="Наименование образовательной организации, которая внесла запись (краткое)"
						required
					/>
				</fieldset>
				<fieldset class="form-row form-row__two-columns">
					<basic-input
						type="date"
						name="education_organization_date_created"
						v-model="filledData.education_organization_date_created"
						:disabled="disabled"
						label="Дата внесения реестровой записи у образовательной организации"
						required
					/>
					<basic-input
						type="text"
						name="education_organization_registry_number"
						v-model="filledData.education_organization_registry_number"
						:disabled="disabled"
						label="Номер реестровой записи у образовательной организации"
						required
					/>
				</fieldset>
				<fieldset class="form-row form-row__one-column form-row__heading">
					<h2 class="subheading">Основная информация</h2>
				</fieldset>
				<fieldset class="form-row form-row__one-column">
					<basic-input
						name="organization_name"
						v-model="filledData.organization_name"
						:disabled="disabled"
						label="Наименование организации (компании дорожного хозяйства)"
						required
					/>
					<basic-input
						name="organizational_and_legal_form"
						v-model="filledData.organizational_and_legal_form"
						:disabled="disabled"
						label="Организационно-правовая форма"
						required
					/>
				</fieldset>
				<fieldset class="form-row form-row__one-column">
					<basic-input
						name="organization_name_short"
						v-model="filledData.organization_name_short"
						:disabled="disabled"
						label="Наименование организации (сокращенное)"
						required
					/>
				</fieldset>
				<fieldset class="form-row form-row__two-columns with-checkbox">
					<basic-input
						type="text"
						name="inn"
						v-model="filledData.inn"
						:disabled="disabled"
						label="ИНН"
						required
						:pattern="innPattern"
					/>
					<input-checkbox
						v-model="individualEntrepreneur"
						name="individual_entrepreneur"
						id="individual_entrepreneur"
						label="ИП"
					/>
				</fieldset>
				<fieldset class="form-row form-row__two-columns">
					<select-input
						name="organization_type_id"
						v-model="filledData.organization_type_id"
						:options="selectOptions.organization_types"
						:first-option="selectOptions.organization_types[0]"
						label="Тип организации"
						:disabled="disabled"
						required
					/>
					<select-input
						name="organization_level_id"
						v-model="filledData.organization_level_id"
						label="Уровень организации"
						:options="selectOptions.organization_levels"
						:first-option="selectOptions.organization_levels[0]"
						:disabled="disabled"
						required
					/>
				</fieldset>
				<fieldset class="form-row form-row__three-columns" v-if="mode === 'new'">
					<basic-input
						name="index"
						v-model="address.index"
						:disabled="disabled"
						pattern="^([0-9]{6})?$"
						label="Индекс"
					/>
					<basic-input
						name="addressRegion"
						v-model="address.region"
						:disabled="disabled"
						label="Область/Край/Район"
						required
					/>
					<basic-input
						name="city"
						v-model="address.city"
						:disabled="disabled"
						label="Город"
						required
					/>
					<basic-input
						name="street"
						v-model="address.street"
						:disabled="disabled"
						label="Улица"
						required
					/>
					<basic-input
						name="house"
						v-model="address.house"
						:disabled="disabled"
						label="Дом, корпус/строение"
						required
					/>
					<basic-input
						name="office"
						v-model="address.office"
						:disabled="disabled"
						label="Офис/помещение"
					/>
				</fieldset>
				<fieldset class="form-row form-row__one-column" v-if="mode === 'edit'">
					<basic-input
						name="address"
						v-model="filledData.address"
						:disabled="disabled"
						label="Адрес"
						required
					/>
				</fieldset>
				<fieldset class="form-row form-row__two-columns">
					<select-input
						name="federal_district_id"
						v-model="filledData.federal_district_id"
						label="Федеральный округ"
						:disabled="disabled"
						:options="selectOptions.districts"
						:first-option="selectOptions.districts[0]"
						required
					/>
					<select-input
						name="russian_federation_subject_id"
						v-model="filledData.russian_federation_subject_id"
						label="Наименование субъекта РФ"
						:disabled="disabled"
						:options="russian_federation_subjects"
						:first-option="russian_federation_subjects[0]"
						required
					/>
				</fieldset>
				<fieldset class="form-row form-row__three-columns">
					<basic-input
						type="tel"
						name="phone_number_primary"
						v-model="filledData.phone_number_primary"
						:disabled="disabled"
						label="Телефон (1)"
						required
					/>
					<basic-input
						type="tel"
						name="phone_number_secondary"
						v-model="filledData.phone_number_secondary"
						:disabled="disabled"
						label="Телефон (2)"
						required
					/>
					<basic-input
						type="tel"
						name="fax"
						v-model="filledData.fax"
						:disabled="disabled"
						label="Факс"
						required
					/>
				</fieldset>
				<fieldset class="form-row form-row__two-columns">
					<basic-input
						type="email"
						name="email_primary"
						v-model="filledData.email_primary"
						:disabled="disabled"
						label="Электронная почта (1)"
						required
					/>
					<basic-input
						type="email"
						name="email_secondary"
						v-model="filledData.email_secondary"
						:disabled="disabled"
						label="Электронная почта (2)"
						required
					/>
				</fieldset>
				<fieldset class="form-row form-row__one-column">
					<input-checkbox
						v-model="filledData.far_north_region"
						name="far_north_region"
						id="far_north_region"
						label="Категория района Крайнего Севера и приравненных к ним местностей"
					/>
				</fieldset>
				<fieldset class="form-row form-row__one-column form-row__heading">
					<h2 class="subheading">Контактное лицо</h2>
				</fieldset>
				<fieldset class="form-row form-row__three-columns">
					<basic-input
						name="name"
						v-model="filledData.contact_person.name"
						:disabled="disabled"
						label="Имя"
						required
					/>
					<basic-input
						name="surname"
						v-model="filledData.contact_person.surname"
						:disabled="disabled"
						label="Фамилия"
						required
					/>
					<basic-input
						name="middlename"
						v-model="filledData.contact_person.middlename"
						:disabled="disabled"
						label="Отчество"
						required
					/>
				</fieldset>
				<fieldset class="form-row form-row__two-columns">
					<basic-input
						type="tel"
						name="contact_person_phone_number_primary"
						v-model="filledData.contact_person.phone_number_primary"
						:disabled="disabled"
						label="Телефон (1)"
						required
					/>
					<basic-input
						type="tel"
						name="contact_person_phone_number_secondary"
						v-model="filledData.contact_person.phone_number_secondary"
						:disabled="disabled"
						label="Телефон (2)"
						required
					/>
					<basic-input
						type="tel"
						name="contact_person_fax"
						v-model="filledData.contact_person.fax"
						:disabled="disabled"
						label="Факс"
						required
					/>
					<basic-input
						type="email"
						name="contact_person_email"
						v-model="filledData.contact_person.email"
						:disabled="disabled"
						label="Электронная почта"
						required
					/>
				</fieldset>
				<fieldset class="form-row form-row__one-column">
					<basic-input
						name="position"
						v-model="filledData.contact_person.position"
						:disabled="disabled"
						label="Должность"
						required
					/>
				</fieldset>
				<fieldset  v-if="mode === 'edit' || mode === 'new'" class="form-row__submit">
					<button type="submit" class="btn__blue">Отправить</button>
				</fieldset>
			</fieldset>
		</form>
		{{fullAddress}}
	</section>

	<message-after-send
		v-else
		:messages="messages"
		:links="links"
		@restartPage="restartPage"
		:status="getStatus"
	/>
</template>

<script>
import BasicInput from '@/components/forms/inputs/BasicInput.vue'
import SelectInput from '@/components/forms/inputs/SelectInput.vue'
import InputCheckbox from '@/components/forms/inputs/InputCheckbox.vue'
import MessageAfterSend from '@/components/forms/MessageAfterSend.vue'

import { mapActions, mapGetters } from 'vuex'

export default {
	name: "OrganizationRegistryForm",

	components: { BasicInput, SelectInput, InputCheckbox, MessageAfterSend },

	data: () => ({
		individualEntrepreneur: false,
		address: {
			index: '',
			region: '',
			city: '',
			street: '',
			house: '',
			office: '',
		},
		filledData: {
			registry_item_number: "",
			date_created: "",
			education_organization: "",
			education_organization_registry_number: "",
			education_organization_date_created: "",
			organization_name: "",
			organization_name_short: "",
			organizational_and_legal_form: "",
			inn: "",
			organization_type_id: "",
			organization_level_id: "",
			address: "",
			federal_district_id: "",
			russian_federation_subject_id: "",
			far_north_region: null,
			phone_number_primary: "",
			phone_number_secondary: "",
			fax: "",
			email_primary: "",
			email_secondary: "",
			contact_person: {
				name: "",
				surname: "",
				position: "",
				middlename: "",
				phone_number_primary: "",
				phone_number_secondary: "",
				email: "",
				fax: ""
			},
		},
	}),

	props: {
		mode: {
			type: String,
			default: 'edit',
			validate: (v) => ['edit', 'read', 'new'].includes(v)
		},
		formData: {
			type: Object,
			required: false,
			default: () => ({})
		},
		selectOptions: {
			type: [Object, Array],
			required: true,
			default: () => ({})
		}
	},

	watch: {
		fullAddress(value) {
			this.filledData.address = value
		}
	},

	computed: {
		...mapGetters(['getStatus']),
		fullAddress() {
			let addr = ''
			Object.values(this.address).forEach((el, key, arr) => {
				if(el !== '') addr += (el + (key + 1 < arr.length ? ', ' : ''))
			})
			return addr
		},
		disabled() {
			return this.mode === 'read' || false
		},
		russian_federation_subjects() {
			return this.selectOptions.subjects.map(subj => (subj.subject_name))
		},
		messages() {
			return {
				success: 'Организация сохранена',
				conflict: 'Такая организация уже есть',
				error: 'Во время отправки формы произошла ошибка, попробуйте еще раз, или сообщите администратору',
				another_page: 'Вернуться к списку организаций',
				new_one: this.mode === 'edit' ? 'Отредактировать еще раз' : 'Отправить еще одну форму',
			}
		},
		links() {
			return {
				another_page: { name: 'OrganizationList' },
			}
		},
		innPattern() {
			return this.individualEntrepreneur ? '^([0-9]{12})?$' : '^([0-9]{10})?$'
		}
	},

	methods: {
		...mapActions(['cleanStatusCode']),
		submit() {
			this.$emit('submit', this.filledData)
		},
		restartPage() {
			this.cleanStatusCode()
			this.$emit('restartPage')
		}
	},
	mounted() {
		if(this.formData.id !== undefined) {
			this.filledData = this.formData
			console.log(this.formData)
			this.filledData.far_north_region = (this.filledData.far_north_region === 'true')
			this.filledData.date_created = this.getDateForInput(this.filledData.date_created)
			this.filledData.education_organization_date_created = this.getDateForInput(this.filledData.education_organization_date_created)
		}
	},
	beforeDestroy() {
		this.cleanStatusCode()
	},
	created() {
		this.cleanStatusCode()
	}
}
</script>

<style scoped>
	.vcc-main {
		padding: 0 10px;
	}
	.with-checkbox {
		align-items: center !important;
	}
</style>
